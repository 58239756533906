<template>
  <WModalContent
    :isOpen="isOpen"
    @close="handleClose"
  >
    <template #header>
      <h4 class="heading">
        {{ $t('reactiveLogs.modalMACIP') }}
      </h4>
    </template>
    <template #body>
      <div class="body">
        <div class="explanation">
          <small>{{ $t('reactiveLogs.modalMACIPInputHint') }}</small>
        </div>
        <WTextarea sm
          v-model.trim="inputsData.macAddresses"
          name="macIp"
          id="macIp"
          cols="30"
          rows="10"
          v-validate="'required|ipOrMacListForReactiveLogs|ipOrMacFilterMaxItemsPerStringForReactiveLogs'"
          data-vv-validate-on="input"
          :data-vv-as="$t('reactiveLogs.modalMACIP')"
          :errorText="errors.first('macIp')"
        />
      </div>
    </template>
    <template #footer>
      <div class="button-section">
        <WButton secondary outline @click="handleClose">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton success outline @click="addNewFilters">
          {{ $t('general.add') }}
        </WButton>
      </div>
    </template>
  </WModalContent>
</template>

<script>
/**
 * компонент - модалка для добавления нового фильтра для реактивных логов.
 * компонент локальный: используется только в ReactiveLogs.vue
 * @component
 */

export default {
  name: 'AddReactiveLogsFilterModal',
  inject: ['$validator'],
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  watch: {
  },
  data() {
    return {
      inputsData: {
        macAddresses: ''
      }
    };
  },
  methods: {
    resetInputsData() {
      this.inputsData = {
        macAddresses: ''
      };
    },
    async addNewFilters() {
      const isFormValid = await this.checkFormValidity();

      if (!isFormValid) {
        return;
      }

      const arrayOfMacAdresses = this.inputsData.macAddresses.split('\n');

      this.$emit('add', arrayOfMacAdresses);
      this.resetInputsData();
    },
    handleClose() {
      this.resetInputsData();
      this.$emit('close');
    },
    async checkFormValidity() {
      const isFormValid = await this.$validator.validateAll();

      return isFormValid;
    }
  }
};
</script>

<style lang="css" scoped>
  h4 {
    margin: 0;
  }

  .explanation {
    margin-bottom: 8px;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
